export function getCellStyle({ rowIndex }) {
  if (rowIndex % 2 === 1) {
    return {
      backgroundColor: "rgba(255,255,255,0.2)",
    };
  } else {
    return {
      backgroundColor: "rgba(228,228,228,0.2)",
    };
  }
}
export function getMobileViewCellStyle({ rowIndex }) {
 const style = {
    alignSelf: 'center',
    fontSize: '11px',
  }
  if (rowIndex % 2 === 1) {
    return {
      ...style,
      backgroundColor: "rgba(255,255,255,0.2)",
    };
  } else {
    return {
      ...style,
      backgroundColor:"rgba(228,228,228,0.2)",
    };
  }
}
export function getMobileViewHeaderStyle() {
  return {
    height: '30px',
    fontSize: '12px',
  }
}
export function getHeaderStyle() {
  return{
    background: '#fff',
  }
}
