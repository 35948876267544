<template>
  <div class="barging-container h-full overflow-hidden">
    <div class="barging-card" style="padding:0px 0px; background-color:#f2f7fa; border-radius: 10px;">

      <div class="flex justify-end w-full items-center p-1 laptop:pb-2 desktop:pb-8 pr-2 pl-2 pt-2">
        <el-input
          :placeholder="$t('btn.search')"
          style="width: 275px"
          size="small"
          @keyup.enter.native="getList"
          v-model="searchValue"
        >
          <span slot="suffix" @click="getList">
            <svg-icon iconClass="search" />
          </span>
        </el-input>

        <el-button
          v-if="islogistics"
          type="primary"
          @click="dialogFormVisible = true"
          size="small"
        >
          {{ $t("us.addShip") }}
        </el-button>
      </div>

      <div class="box-card barging-table" v-bind:class="{'mobile-view':isMobileScreen}">
        <div class="w-full Comtable">
          <el-table
            :header-cell-style="{
              height: '55px',
              background: '#fff',
              'border-bottom': '2px solid #D6D9E1',
            }"
            v-loading="listLoading"
            :cell-style="getCellStyle"
            :data="tableData"
            style="width: 100%"
            table-layout="auto"
            height="100%"
          >
            <el-table-column type="index" :label="$t('table.no')" align="left" />
            <el-table-column
              prop="shipName"
              :label="$t('table.shipName')"
              align="left"
            >
            </el-table-column>
            <el-table-column
              prop="imoNumber"
              :label="$t('table.iMONumber')"
              align="left"
            >
            </el-table-column>
            <el-table-column :label="$t('table.LOA')" prop="loa" align="left">
            </el-table-column>
            <el-table-column
              :label="$t('table.portOfRegistry')"
              prop="portOfRegistry"
              align="left"
            >
            </el-table-column>
            <el-table-column align="left" fixed="right" v-if="islogistics">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="deleteRow(scope.row, scope.$index)"
                  type="primary"
                  size="small"
                  plain
                >
                  {{ $t("btn.delete") }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="barging-pagination">
        <pagination
          v-show="total > 0"
          style="background: #f2f7fa"
          :total="total"
          :page.sync="pageParams.pageNum"
          :limit.sync="pageParams.pageSize"
          @pagination="getList"
        ></pagination>
      </div>
    </div>
    <el-dialog
      width="360px"
      :title="$t('us.addShip')"
      :visible.sync="dialogFormVisible"
      :before-close="handleBeforeClose"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        size="small"
        label-position="top"
        :model="form"
        :rules="rules"
      >
      <el-form-item :label="$t('table.ship') + ':'" prop="imoNumber">
          <el-select v-model="form.imoNumber" clearable placeholder="" filterable bargeNameloading remote
            :remote-method="handleSearchBarge" class="bargeNameinput" :loading="isBargeOptionsLoading" :style="{ width: '100%' }">
            <el-option v-for="item in bargeoptions" :key="item.value"
              :disabled="item.unFlag == 1" :label="item.imo + `(${item.bargeName})`" :value="item.imo">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item
          :label="$t('table.shipName')"
          :label-width="formLabelWidth"
          prop="shipName"
        >
          <el-input
            size="small"
            v-model="form.shipName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('table.iMONumber')"
          :label-width="formLabelWidth"
          prop="imoNumber"
        >
          <el-input size="small" v-model="form.imoNumber"></el-input>
        </el-form-item> -->
        <el-form-item
          prop="loa"
          :label="$t('table.LOA')"
          :label-width="formLabelWidth"
        >
          <el-input
            size="small"
            v-model="form.loa"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="portOfRegistry"
          :label="$t('table.portOfRegistry')"
          :label-width="formLabelWidth"
        >
          <el-input
            size="small"
            v-model="form.portOfRegistry"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="handleSaveClick"
          >{{ $t("btn.save") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addUnqualifiedShips,
  deleteUnqualifiedShipsList,
  getUnqualifiedShipsList,
} from "@/services/index";
import { searchBarge } from '@/services/user';
import { getCellStyle } from "@/utils/getCellStyle";
import { mapState } from "vuex";
export default {
  name: "ships",
  data() {
    return {
      bargeoptions: [],
      isBargeOptionsLoading: true,
      value2: "",
      listLoading: false,
      searchValue: "",
      tableData: [],
      dialogFormVisible: false,
      form: {
        shipName: "",
        imoNumber: "",
        loa: "",
        portOfRegistry: "",
      },
      formLabelWidth: "120px",
      rules: {
        shipName: [
          { required: true, message: "Please Input", trigger: "blur" },
        ],
        imoNumber: [
          { required: true, message: "Please Input", trigger: ["blur", "change" ]},
        ],
        loa: [{ required: true, message: "Please Input", trigger: "blur" }],
        portOfRegistry: [
          { required: true, message: "Please Input", trigger: "blur" },
        ],
      },
      total: 0,
      pageParams: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.getList();
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    islogistics() {
      return this.userInfo.userType == 1 && this.userInfo.role == 1;
    },
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  methods: {
    async handleSearchBarge (query) {
      if (query !== '') {
        this.isBargeOptionsLoading = true;
        try {
          const res = await searchBarge(query);
          this.bargeoptions = res.data;
        } catch (error) {
          this.bargeoptions = [];
        } finally {
          this.isBargeOptionsLoading = false;
        }
      } else {
        this.bargeoptions = [];
      }
    },
    handleBeforeClose(done) {
      this.bargeoptions = []
      this.$refs.form.resetFields()
      done();
    },
    getList() {
      this.listLoading = true;
      getUnqualifiedShipsList(this.pageParams)
        .then((res) => {
          this.tableData = res.rows;
          this.total = res.total;
          this.tableLoading = false;
        })
        .finally(() => (this.listLoading = false));
    },
    handleSaveClick() {
      this.$refs.form.validate((res) => {
        if (res) {
          this.form.shipName = this.bargeoptions.find(b => b.imo === this.form.imoNumber).bargeName
          addUnqualifiedShips({ ...this.form }).then(() => {
            this.$message.success(this.$t("tips.success"));
            this.getList();
            this.dialogFormVisible = false;
            this.bargeoptions = []
            this.$refs.form.resetFields()
          });
        }
      });
    },
    deleteRow(row, index) {
      deleteUnqualifiedShipsList(row.id).then(() => {
        this.$message.success(this.$t("status.success"));
        this.tableData.splice(index, 1);
      });
    },
    getCellStyle,
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-form-item {
  margin-bottom: 10px;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 18px;
  font-weight: bold;
}
::v-deep .el-dialog__title {
  color: #82889c;
  font-size: 16px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding-bottom: 0;
}
::v-deep .el-table th.el-table__cell {
  font-size: 12px;
  font-weight: bold;
}
::v-deep .el-table td.el-table__cell div {
  font-weight: 400;
  font-size: 11px;

  color: #2e3139;
}
::v-deep .el-input__suffix-inner {
  padding-right: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}

.mobile-view{
  ::v-deep .el-table th.el-table__cell > .cell {
    font-size: 10px;
    line-height:9px;
    color: #1D1B20 !important;
  }
  ::v-deep .el-table__row > .el-table__cell > .cell {
    font-size: 10px;
    line-height:8px; 
  }
}
@media (max-width: 1023px) {
  .barging-card{
    height: calc(100vh - 100px);
  }
  .Comtable {
    height: calc(100vh - 11.5rem);
  }
  .mobile-view{
    .Comtable {
      height: calc(100vh - 11rem);
    }
  }
}
@media (min-width: 1024px) {
  .barging-card{
    height: calc(100vh - 90px);
  }
  .Comtable {
    height: calc(100vh - 11rem);
  }
}
@media (min-width: 1441px) {
  .barging-card{
    height: calc(100vh - 100px);
  }
  .Comtable {
    height: calc(100vh - 13rem);
  }
}
</style>
